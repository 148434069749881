import { api } from '@lib/api'
import { storage } from '@lib/mmkv'
import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { AppState, Appearance } from './types'

export const useAppStore = createWithEqualityFn<AppState>(
  (set, get) => ({
    appearance: undefined,
    setAppearance: (appearance) => {
      storage.set('appearance', appearance)
      set({ appearance })
    },
    initializeAppearance: () => {
      set({ appearance: (storage.get('appearance') || 'system') as Appearance })
    },

    // wait with deeplinking until router is ready
    isRouterInitialized: false,
    setRouterInitialized: () => set({ isRouterInitialized: true }),

    // force-update from BE
    isOutdated: false,
    setOutdated: () => set({ isOutdated: true }),

    // servers unreachable
    isServerDown: false,
    setServerDown: () => set({ isServerDown: true }),

    // releases
    releases: [],
    getReleases: async (offset = 0, limit = 1) => {
      const locale = (storage.get('locale') as SupportedLocaleEnum) || SupportedLocaleEnum.EnUs
      const releases = (await api.getReleases(locale, offset, limit)) ?? []

      set({ releases: [...(offset ? get().releases : []), ...releases] })
    },
  }),
  shallow,
)
