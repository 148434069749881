import { Icon } from '@components/Elements/Icon'
import { Text } from '@components/Elements/Text'
import { t } from '@lib/i18n'
import { useAuthStore } from '@stores/auth'
import { DeviceTypeEnum } from '@vetahealth/tuna-can-api'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import React from 'react'
import { ScrollView } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

const Wrapper = styled.View`
  align-items: center;
  margin-bottom: 30px;
  gap: 8px;
`
const IconWrapper = styled.View`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.tabBarHighlight};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  align-items: center;
  justify-content: center;
`
const DeviceWrapper = styled.View`
  flex-direction: row;
  gap: 5px;
  margin-top: 3px;
`
const Device = styled.View`
  padding: 5px 10px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.deviceBackground};
`

function getDeviceLabel(deviceType: `${DeviceTypeEnum}`): string {
  return {
    [DeviceTypeEnum.BioIntelliSenseButton]: t('settings.devices.bioIntelliSenseButton'),
    [DeviceTypeEnum.BioIntelliSenseSticker]: t('settings.devices.bioIntelliSenseSticker'),
    [DeviceTypeEnum.BodytraceBpCuff]: t('settings.devices.bodytraceBpCuff'),
    [DeviceTypeEnum.GenericBpCuff]: t('settings.devices.genericBpCuff'),
    [DeviceTypeEnum.BodytraceScale]: t('settings.devices.bodytraceScale'),
    [DeviceTypeEnum.GenericScale]: t('settings.devices.genericScale'),
    [DeviceTypeEnum.SmartMeterIScale]: t('settings.devices.smartMeterIScale'),
    [DeviceTypeEnum.SmartMeterIGlucose]: t('settings.devices.smartMeterIGlucose'),
    [DeviceTypeEnum.SmartMeterIPulseOx]: t('settings.devices.smartMeterIPulseOx'),
    [DeviceTypeEnum.SmartMeterIBloodPressure]: t('settings.devices.smartMeterIBloodPressure'),
    [DeviceTypeEnum.SmartMeterIBloodPressureXl]: t('settings.devices.smartMeterIBloodPressureXL'),
    [DeviceTypeEnum.TenoviBpCuffL]: t('settings.devices.tenoviBpCuffL'),
    [DeviceTypeEnum.TenoviBpCuffS]: t('settings.devices.tenoviBpCuffS'),
    [DeviceTypeEnum.TenoviGlucometer]: t('settings.devices.tenoviGlucometer'),
    [DeviceTypeEnum.TenoviPulseOx]: t('settings.devices.tenoviPulseOx'),
    [DeviceTypeEnum.TenoviScale]: t('settings.devices.tenoviScale'),
  }[deviceType]
}

export function Profile(): JSX.Element | null {
  const theme = useTheme()
  const [user] = useAuthStore((state) => [state.user])
  const name = `${user?.firstName} ${user?.lastName}`

  if (!user) return null

  return (
    <Wrapper>
      <IconWrapper>
        <Icon icon={['fad', 'user']} size={20} color={theme.colors.primary} />
      </IconWrapper>
      <Text weight="semiBold" size="heading4" disableLineHeight>
        {name}
      </Text>
      <Text weight="semiBold" size="settings" disableLineHeight>
        {!!user.phone && parsePhoneNumberWithError(user.phone).format('NATIONAL')}
      </Text>
      <ScrollView horizontal>
        <DeviceWrapper>
          {user?.deviceTypes.map((deviceType) => (
            <Device key={deviceType}>
              <Text size="tiny" weight="medium" disableLineHeight color={theme.colors.deviceText}>
                {getDeviceLabel(deviceType)}
              </Text>
            </Device>
          ))}
        </DeviceWrapper>
      </ScrollView>
    </Wrapper>
  )
}
