import { ValidUnits } from '@vetahealth/fishing-gear/conversions'
import { TrackingChartAssetTrackingTypeEnum } from '@vetahealth/tuna-can-api'
import React from 'react'

import { useI18n } from '@lib/i18n'

import { TrackingInput } from './TrackingInput'
import { Message, MessageWrapper } from './styles'
import { getTrackingConfig } from './trackingConfig'
import { MaunalTrackingChartAssetType, TrackingFormProps } from './types'

export function TrackingForm({ trackingType, onSuccessCallback, event }: TrackingFormProps): JSX.Element {
  const t = useI18n()

  const { addValue, unit } = getTrackingConfig(trackingType)

  return addValue ? (
    <TrackingInput
      unit={unit as ValidUnits[MaunalTrackingChartAssetType]}
      addValue={addValue}
      trackingType={trackingType as MaunalTrackingChartAssetType}
      onSuccessCallback={onSuccessCallback}
      event={event}
    />
  ) : (
    <MessageWrapper>
      <Message>
        {{
          [TrackingChartAssetTrackingTypeEnum.BloodPressure]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.HeartRate]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.HeartRateVariability]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.BloodGlucose]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.Weight]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.RespiratoryRate]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.BodyTemperature]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.Sleep]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.Steps]: t('tracking.common.deviceUsage'),
          [TrackingChartAssetTrackingTypeEnum.QualityOfLife]: t('tracking.common.survey'),
          [TrackingChartAssetTrackingTypeEnum.MedicationAdherence]: t('tracking.common.survey'),
          [TrackingChartAssetTrackingTypeEnum.Height]: undefined,
          [TrackingChartAssetTrackingTypeEnum.BloodOxygen]: t('tracking.common.deviceUsage'),
        }[trackingType] || null}
      </Message>
    </MessageWrapper>
  )
}
