import { Connection, ErrorBoundary, OutdatedApp, ServerDown } from '@components/AppStatus'
import { getTheme } from '@components/styles'
import { CallbackType, api } from '@lib/api'
import { isWeb } from '@lib/constants'
import { initializeLocales } from '@lib/i18n'
import { resetTracking } from '@lib/tracking'
import { Router } from '@router'
import { useAppStore } from '@stores/app'
import { useAuthStore } from '@stores/auth'
import { useChatStore } from '@stores/chat'
import { useOnboardingStore } from '@stores/onboarding'
import { useTasksStore } from '@stores/tasks'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { TokenResponse } from '@vetahealth/tuna-can-api'
import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { AppState, AppStateStatus, Linking, StatusBar, useColorScheme, useWindowDimensions } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { KeyboardProvider } from 'react-native-keyboard-controller'
import { ReanimatedLogLevel, configureReanimatedLogger } from 'react-native-reanimated'
import styled, { ThemeProvider } from 'styled-components/native'

configureReanimatedLogger({
  level: ReanimatedLogLevel.warn,
  strict: false, // Disable strict mode
})

const AppWrapper = styled.View`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  min-width: ${({ theme }) => theme.minWidth}px;
  max-width: ${({ theme }) => theme.maxWidth}px;
  background-color: ${({ theme }) => theme.colors.background};
`

export function App() {
  const appState = useRef<AppStateStatus>(AppState.currentState)
  const [appearance, initializeAppearance, isOutdated, isServerDown] = useAppStore((state) => [
    state.appearance,
    state.initializeAppearance,
    state.isOutdated,
    state.isServerDown,
  ])
  const [setDeepLink] = useOnboardingStore((state) => [state.setDeepLink])
  const colorScheme = useColorScheme()
  const { width, height } = useWindowDimensions()

  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [hasInternetConnection, setInternetConnection] = useState<boolean>(true)

  const [resetTasks] = useTasksStore((state) => [state.reset])
  const [setAuthorized, setUnauthorized] = useAuthStore((state) => [state.setAuthorized, state.setUnauthorized])
  const [getChatMessages] = useChatStore((state) => [state.getChatMessages])

  function handleTokenRetrieval(tokenResponse?: TokenResponse): void {
    if (tokenResponse) setAuthorized(tokenResponse)
  }

  function handleTokenExpiration(): void {
    setUnauthorized()
    resetTasks()
    resetTracking()
  }

  function handleAppStateChanged(nextAppState: AppStateStatus): void {
    if (
      // don't check on cold start
      ['inactive', 'background'].includes(appState.current) &&
      nextAppState === 'active'
    ) {
      api.checkTokenValidity()
      getChatMessages()
    }

    appState.current = nextAppState
  }

  async function initializeApi(): Promise<void> {
    try {
      await api.initialize()
      setIsInitialized(true)
    } catch (error) {
      if ((error as Error).message === '504') setInternetConnection(false)
    }
  }

  useLayoutEffect(() => {
    initializeLocales()
    initializeAppearance()

    Linking.getInitialURL().then((url) => url && setDeepLink(url))

    const linkingSubscription = !isWeb ? Linking.addEventListener('url', ({ url }) => setDeepLink(url)) : undefined
    const appStateSubscription = AppState.addEventListener('change', handleAppStateChanged)

    api.registerCallback(CallbackType.onTokenRetrieved, handleTokenRetrieval)
    api.registerCallback(CallbackType.onTokenExpired, handleTokenExpiration)

    return () => {
      linkingSubscription?.remove()

      api.removeCallback(CallbackType.onTokenRetrieved, handleTokenRetrieval)
      api.removeCallback(CallbackType.onTokenExpired, handleTokenExpiration)

      appStateSubscription.remove()
    }
  }, [])

  useLayoutEffect(() => {
    if (hasInternetConnection) {
      initializeApi()
    }
  }, [hasInternetConnection])

  function renderComponent(): ReactNode {
    if (!hasInternetConnection) return <Connection redo={() => setInternetConnection(true)} />
    if (isServerDown) return <ServerDown />
    if (isOutdated) return <OutdatedApp />
    if (isInitialized) return <Router />

    return null
  }

  const userColorScheme = appearance === 'system' ? colorScheme : appearance
  const isDark = userColorScheme === 'dark'

  useLayoutEffect(() => {
    if (isWeb) {
      document.body.style.backgroundColor = userColorScheme === 'dark' ? Colors.anthrazit500 : Colors.gray100
    }
  }, [userColorScheme])

  if (!appearance) return null

  return (
    <KeyboardProvider>
      <GestureHandlerRootView>
        <ThemeProvider theme={getTheme({ colorScheme: userColorScheme, width, height })}>
          <AppWrapper>
            <ErrorBoundary>
              <>
                <StatusBar
                  barStyle={isDark ? 'light-content' : 'dark-content'}
                  backgroundColor={isDark ? Colors.anthrazit575 : Colors.mauve50}
                />
                {renderComponent()}
              </>
            </ErrorBoundary>
          </AppWrapper>
        </ThemeProvider>
      </GestureHandlerRootView>
    </KeyboardProvider>
  )
}
