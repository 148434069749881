import { yupResolver } from '@hookform/resolvers/yup'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { getInputSchema } from '@lib/validation'
import { DeepLinkParams, useOnboardingStore } from '@stores/onboarding'
import { useToastsStore } from '@stores/toasts'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button } from '../Elements/Button'
import { PhoneInput } from '../Elements/Input'
import {
  ButtonWrapper,
  ContentWrapper,
  Description,
  ErrorMessage,
  OnboardingAnimationWrapper,
  Title,
  useOnboardingAnimation,
} from './styles'

export function Phone({ params }: { params?: DeepLinkParams }): JSX.Element {
  const t = useI18n()
  const [error, setError] = useState('')
  const style = useOnboardingAnimation()

  const [addToast] = useToastsStore((state) => [state.addToast])
  const [input, requestOtp, updateInput] = useOnboardingStore((state) => [
    state.input,
    state.requestOtp,
    state.updateInput,
  ])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValidating },
  } = useForm({
    resolver: yupResolver(getInputSchema().pick(['phone'])),
    defaultValues: { phone: input.phone },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleFormSubmit = useDebounce(() =>
    handleSubmit(async ({ phone }) => {
      if (phone) {
        updateInput({ phone: parsePhoneNumberWithError(phone).number })

        await requestOtp().catch((error) => {
          if (error?.cause === 'AccountNotFoundError') {
            addToast({ message: t('error.accountNotFound') })
          } else if (error?.cause === 'AccountIsLockedError') {
            setError(t('error.accountLocked'))
          }
        })
      }
    })(),
  )

  return (
    <OnboardingAnimationWrapper style={style}>
      <ContentWrapper>
        {!!error && <ErrorMessage message={error} />}
        <Title>{params?.isReturningUser ? t('onboarding.returning.welcome') : t('onboarding.welcome')}</Title>
        <Description>{params?.isReturningUser ? t('onboarding.returning.phone') : t('onboarding.phone')}</Description>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PhoneInput
              value={value}
              onChange={onChange}
              error={error?.message}
              placeholder={t('onboarding.yourPhoneNumber')}
              onSubmitEditing={handleFormSubmit}
              textContentType="telephoneNumber"
            />
          )}
        />
        <ButtonWrapper>
          <Button
            fullWidth
            onPress={handleFormSubmit}
            isLoading={isValidating || isSubmitting}
            label={t('actions.continue')}
          />
        </ButtonWrapper>
      </ContentWrapper>
    </OnboardingAnimationWrapper>
  )
}
