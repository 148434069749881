import { Portal } from '@gorhom/portal'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import Animated, { FadeIn, FadeInUp, FadeOut } from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'

import { Markdown } from '@components/Markdown'

import { Icon } from '../Icon'
import { Pressable } from '../Pressable'
import { Text } from '../Text'
import { DialogProps } from './types'

const Backdrop = styled(Animated.View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${Colors.black}55;
`
const DialogContainer = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex: 1;
  justify-content: center;
`
const DialogWrapper = styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.dialogBackground};
  border-radius: ${({ theme }) => theme.borderRadius.xLarge}px;
  padding: 30px 30px 15px;
  max-width: ${({ theme }) => theme.maxWidth}px;
  max-height: ${({ theme }) => theme.height * 0.9}px;
  margin: 0 20px;
  top: 0;
`
const DialogContent = styled.View`
  overflow: hidden;
`
const DialogMarkdownWrapper = styled.View`
  margin-top: 10px;
`
const DialogScrollView = styled.ScrollView`
  max-height: ${({ theme }) => theme.height / 2}px;
`
const IconWrapper = styled.View`
  margin-bottom: 10px;
`
const ActionWrapper = styled.View`
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`
const DialogButton = styled(Pressable)`
  margin-top: 10px;
  padding: 5px 20px;
`

export function Dialog({
  icon,
  title,
  message,
  children,
  isVisible,
  primaryAction,
  secondaryAction,
  isScrollable = false,
  markdown = {},
}: DialogProps): JSX.Element | null {
  const theme = useTheme()

  if (!isVisible) return null

  return (
    <Portal hostName="dialog">
      <Backdrop entering={FadeIn} exiting={FadeOut} />
      <DialogContainer>
        <DialogWrapper entering={FadeInUp} exiting={FadeOut}>
          <DialogContent>
            {icon && (
              <IconWrapper>
                <Icon icon={icon} size={24} />
              </IconWrapper>
            )}
            {title && (
              <Text weight="bold" size="large">
                {title}
              </Text>
            )}
            <DialogScrollView scrollEnabled={isScrollable}>
              {message && (
                <DialogMarkdownWrapper>
                  <Markdown defaultWeight="medium" defaultFontSize="dialog" {...markdown} text={message} />
                </DialogMarkdownWrapper>
              )}
              {!!children && children}
            </DialogScrollView>
            <ActionWrapper>
              {secondaryAction && (
                <DialogButton
                  onPress={secondaryAction.onPress}
                  isDisabled={secondaryAction.isDisabled}
                  render={() => (
                    <Text color={secondaryAction.color || theme.colors.primary} weight="semiBold">
                      {secondaryAction.label}
                    </Text>
                  )}
                />
              )}
              <DialogButton
                onPress={primaryAction.onPress}
                isDisabled={primaryAction.isDisabled}
                render={() => (
                  <Text color={primaryAction.color || theme.colors.primary} weight="semiBold">
                    {primaryAction.label}
                  </Text>
                )}
              />
            </ActionWrapper>
          </DialogContent>
        </DialogWrapper>
      </DialogContainer>
    </Portal>
  )
}
