import { Colors } from '@vetahealth/fishing-gear/colors'
import { ColorSchemeName, Platform } from 'react-native'
import { DefaultTheme } from 'styled-components/native'

export function getTheme({
  colorScheme = 'light',
  width,
  height,
}: {
  colorScheme?: ColorSchemeName
  width: number
  height: number
}): DefaultTheme {
  const isLight = colorScheme !== 'dark'

  const lightColors = {
    primary: Colors.mauve600,
    secondary: Colors.mauve150,
    background: Colors.mauve75,
    secondaryBackground: Colors.mauve100,
    secondaryBackgroundHover: Colors.gray150,
    tertiaryBackground: Colors.white,
    text: Colors.black,
    secondaryText: Colors.gray600,
    invertedText: Colors.white,
    disabled: Colors.mauve300,
    error: Colors.ruby600,
    errorBackground: Colors.ruby100,
    hint: Colors.gray700,
    border: Colors.mauve100,
    scale: Colors.white,
    scaleHover: Colors.mauve200,
    closeToast: Colors.white,
    toggle: Colors.mauve600,
    toastBackground: Colors.anthrazit500,
    dialogBackground: Colors.mauve75,
    tabBarBackground: Colors.white,
    tabBarHighlight: Colors.mauve150,
    tabBarIcon: Colors.mauve800,
    chartGridLines: Colors.gray300,
    trackingEventBackground: Colors.white,
    deviceBackground: Colors.mauve150,
    deviceText: Colors.mauve900,
    trackingCardInsight: Colors.mauve700,
  }

  const darkColors = {
    primary: Colors.mauve600,
    secondary: Colors.anthrazit500,
    background: Colors.anthrazit575,
    secondaryBackground: Colors.anthrazit500,
    secondaryBackgroundHover: Colors.gray900,
    tertiaryBackground: Colors.anthrazit550,
    text: Colors.white,
    secondaryText: Colors.gray200,
    invertedText: Colors.black,
    disabled: Colors.gray900,
    error: Colors.ruby400,
    errorBackground: Colors.ruby900,
    hint: Colors.gray400,
    border: Colors.gray800,
    scale: Colors.anthrazit500,
    scaleHover: Colors.gray900,
    closeToast: Colors.anthrazit500,
    toggle: Colors.gray600,
    toastBackground: Colors.mauve50,
    dialogBackground: Colors.anthrazit500,
    tabBarBackground: Colors.anthrazit500,
    tabBarHighlight: Colors.anthrazit600,
    tabBarIcon: Colors.mauve400,
    chartGridLines: Colors.gray500,
    trackingEventBackground: Colors.anthrazit500,
    deviceBackground: Colors.anthrazit500,
    deviceText: Colors.mauve400,
    trackingCardInsight: Colors.mauve800,
  }

  const font = {
    weight: {
      normal: {
        fontFamily: Platform.select({
          native: 'Montserrat-Regular',
          web: 'Montserrat',
        }),
        fontWeight: 400,
      },
      medium: {
        fontFamily: Platform.select({
          native: 'Montserrat-Medium',
          web: 'Montserrat',
        }),
        fontWeight: 500,
      },
      semiBold: {
        fontFamily: Platform.select({
          native: 'Montserrat-SemiBold',
          web: 'Montserrat',
        }),
        fontWeight: 600,
      },
      bold: {
        fontFamily: Platform.select({
          native: 'Montserrat-Bold',
          web: 'Montserrat',
        }),
        fontWeight: 700,
      },
    },
    size: {
      tiny: 11,
      small: 12,
      medium: 16,
      large: 18,
      xLarge: 24,
      xxLarge: 32,
      button: 14,
      toast: 13,
      dialog: 14,
      settings: 14,
      toggle: 14,
      heading4: 20,
      heading2: 28,
    },
  }

  const borderRadius = {
    tiny: 3,
    small: 5,
    medium: 12,
    large: 20,
    xLarge: 28,
    switch: 8,
  }

  const maxWidth = 600

  return {
    height,
    width: width < maxWidth ? width : maxWidth,
    minWidth: 320,
    maxWidth,
    isLight,
    colors: isLight ? lightColors : darkColors,
    font,
    borderRadius,
  }
}
