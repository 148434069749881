import { config } from '@lib/config'
import { appVersion, isDev, isWeb, timeZone, tunaApiVersion } from '@lib/constants'
import { storage } from '@lib/mmkv'
import { takeLeading } from '@lib/takeLeading'
import {
  ChatMessage,
  Configuration,
  ConfigurationParameters,
  DolphinAccountUpdate,
  DolphinApi,
  GenericValueEvent,
  GenericValueEventWithId,
  Info,
  InfoApi,
  InfoScreen,
  LastTrackingEvents,
  PreviewMessageBody,
  PreviewSearchDto,
  PreviewTask,
  PublicDolphinApi,
  ReleaseInfo,
  RequestOtpResponse,
  SharedApi,
  SiteIntro,
  SupportedLocaleEnum,
  Task,
  TaskResponseBody,
  TokenResponse,
  TrackingTypeEnum,
  UserInfo,
  UserInteractionResponse,
  Welcome,
} from '@vetahealth/tuna-can-api'
import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'
import { Platform } from 'react-native'

import { handleApiError } from './error'
import { Callback, CallbackType } from './types'

function handleInternalUrl(url: string): string {
  return Platform.select({
    ios: url,
    android: url?.replace('localhost', '10.0.2.2'),
    web: isDev ? window?.location?.origin : url,
  }) as string
}

class ApiClient {
  private token?: string
  private tunaUrl: string = handleInternalUrl(config.tunaUrl)

  private callbacks: Record<CallbackType, Callback[]> = {
    [CallbackType.onTokenExpired]: [],
    [CallbackType.onTokenRetrieved]: [],
  }

  private runCallbacks = <Payload>(callbackType: CallbackType, payload?: Payload): void => {
    this.callbacks[callbackType].forEach((callback) => {
      callback(payload)
    })
  }

  registerCallback = <Payload>(callbackType: CallbackType, callback: Callback<Payload>): void => {
    this.callbacks[callbackType].push(callback)
  }

  removeCallback = (callbackType: CallbackType, callback: Callback): void => {
    this.callbacks[callbackType] = this.callbacks[callbackType].filter(
      (registeredCallback) => registeredCallback !== callback,
    )
  }

  private call = async <Response>(
    method: () => Promise<Response | undefined>,
    ignoredStatusCodes: number[] = [],
  ): Promise<Response | undefined> => {
    try {
      return await method()
    } catch (error: any) {
      handleApiError(error, ignoredStatusCodes)
    }
  }

  private isTokenExpired = (token: string, offsetSeconds = 0): boolean => {
    const now = Date.now()
    const { exp: expirationInSeconds } = jwtDecode<{ exp: number }>(token)

    return now > (expirationInSeconds - offsetSeconds) * 1000
  }

  private getToken = takeLeading(async (): Promise<string | undefined> => {
    const isValidToken = this.token && !this.isTokenExpired(this.token, 15)

    if (!isValidToken) {
      await this.refreshToken()
    }

    return this.token
  })

  private getBaseOptions = (apiVersion: string) => ({
    headers: {
      'X-Client-Name': 'dolphin',
      'X-Api-Version': apiVersion,
      [Platform.select({
        native: 'X-App-Version',
        web: 'X-Client-Version',
      }) as string]: isDev && isWeb ? 'dev' : appVersion,
    },
  })

  initialize = async (): Promise<void> => {
    await this.getInfo()
    await this.getToken()
  }

  checkTokenValidity = async (): Promise<void> => {
    await this.getToken()
  }

  private getPublicDolphinApi = (configuration: ConfigurationParameters = {}): PublicDolphinApi => {
    return new PublicDolphinApi(
      new Configuration({
        basePath: this.tunaUrl,
        baseOptions: this.getBaseOptions(tunaApiVersion),
        ...configuration,
      }),
    )
  }

  signIn = async ({
    phone,
    userId,
    code,
    dateOfBirth,
  }: {
    phone?: string
    userId?: string
    code: string
    dateOfBirth?: string
  }): Promise<void> => {
    return await this.call(async () => {
      const publicDolphinApi = this.getPublicDolphinApi()
      const params = { phone, userId, code, timeZone, dateOfBirth }

      const { data } = await publicDolphinApi.signInUser(params)
      this.token = data.accessToken
      storage.set('refreshToken', data.refreshToken)
      this.runCallbacks<TokenResponse>(CallbackType.onTokenRetrieved, data)
    }, [403, 406])
  }

  requestOtp = async ({
    phone,
    userId,
  }: {
    phone?: string
    userId?: string
  }): Promise<RequestOtpResponse | undefined> => {
    const publicDolphinApi = this.getPublicDolphinApi()

    return this.call(async () => {
      const { data } = await publicDolphinApi.requestOtp({
        phone,
        userId,
        locale: i18next.language,
      })

      return data
    }, [403, 404])
  }

  renewDeepLink = async (userId: string, deepLink: string) => {
    const publicDolphinApi = this.getPublicDolphinApi()

    return this.call(async () => await publicDolphinApi.renewLink(userId, { link: deepLink }), [403])
  }

  private getApi = async (refreshToken?: string, shared = false): Promise<DolphinApi | SharedApi | undefined> => {
    const token = refreshToken || (await this.getToken())

    if (!token) return

    const config = new Configuration({
      accessToken: token,
      basePath: this.tunaUrl,
      baseOptions: this.getBaseOptions(tunaApiVersion),
    })
    return shared ? new SharedApi(config) : new DolphinApi(config)
  }

  private getDolphinApi = async (refreshToken?: string): Promise<DolphinApi | undefined> => {
    const api = await this.getApi(refreshToken)
    return api instanceof DolphinApi ? api : undefined
  }

  private getSharedApi = async (refreshToken?: string): Promise<SharedApi | undefined> => {
    const api = await this.getApi(refreshToken, true)
    return api instanceof SharedApi ? api : undefined
  }

  private refreshToken = takeLeading(async (): Promise<void> => {
    const refreshToken = storage.get('refreshToken')
    const isValidRefreshToken = refreshToken && !this.isTokenExpired(refreshToken)

    if (!isValidRefreshToken) {
      this.runCallbacks(CallbackType.onTokenExpired)
      return
    }

    try {
      const dolphinApi = await this.getDolphinApi(refreshToken)
      const tokenResponse = await this.call(async () => dolphinApi?.refreshUserToken(), [401])

      if (!tokenResponse) {
        this.runCallbacks(CallbackType.onTokenExpired)
        return
      }

      this.token = tokenResponse.data.accessToken
      storage.set('refreshToken', tokenResponse.data.refreshToken)
      this.runCallbacks(CallbackType.onTokenRetrieved, tokenResponse.data)
    } catch (_) {
      storage.delete('refreshToken')
      this.runCallbacks(CallbackType.onTokenExpired)
    }
  })

  signOut = async ({
    withoutRequest,
  }: {
    withoutRequest?: boolean
  } = {}): Promise<void> => {
    if (!withoutRequest) {
      const dolphinApi = await this.getDolphinApi()
      await this.call(async () => dolphinApi?.signOutUser())
    }

    this.token = undefined
    storage.delete('refreshToken')
    this.runCallbacks(CallbackType.onTokenExpired)
  }

  updateAccount = async (accountUpdate: DolphinAccountUpdate): Promise<UserInfo | undefined> => {
    return await this.call(async () => {
      const dolphinApi = await this.getDolphinApi()
      const response = await this.call(async () => dolphinApi?.updateUserAccount(accountUpdate))

      if (!response) {
        this.runCallbacks(CallbackType.onTokenExpired)
        return
      }

      this.token = response.data.accessToken
      storage.set('refreshToken', response?.data.refreshToken)
      this.runCallbacks<TokenResponse>(CallbackType.onTokenRetrieved, response.data)

      return response.data.info
    })
  }

  getWelcome = async (locale: SupportedLocaleEnum): Promise<Welcome | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.getWelcome(locale))

    return response?.data
  }

  getActiveTask = async (): Promise<Task | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.getActiveTask())

    return response?.data
  }

  getTaskByRef = async (ref: string): Promise<Task | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.getTaskByRef(ref))

    return response?.data
  }

  submitTask = async (taskResponse: TaskResponseBody): Promise<UserInteractionResponse | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.completeTask(taskResponse))

    return response?.data
  }

  getLastTrackingEvents = async (): Promise<LastTrackingEvents | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.getLastTrackingEvents())

    return response?.data
  }

  getTrackingEventsByType = async (
    trackingType: Exclude<
      TrackingTypeEnum,
      typeof TrackingTypeEnum.BloodOxygen | typeof TrackingTypeEnum.HbA1c | typeof TrackingTypeEnum.Height
    >,
  ): Promise<GenericValueEventWithId[] | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.getTrackingEventsByType(trackingType))

    return response?.data
  }

  addTrackingEvent = async (trackingEvent: GenericValueEvent): Promise<UserInteractionResponse | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.addTrackingEvent(trackingEvent))

    return response?.data
  }

  updateTrackingEventNote = async (
    event: GenericValueEventWithId,
    note: string | null,
  ): Promise<boolean | undefined> => {
    const dolphinApi = await this.getDolphinApi()

    return await this.call(async () => {
      await dolphinApi?.updateTrackingEventNote(event.id, { note })
      return true
    })
  }

  searchPreviewRefs = async (query: string): Promise<PreviewSearchDto[] | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.searchTaskRefs(query))

    return response?.data
  }

  getPreview = async ({
    ref,
    locale,
  }: {
    ref: string
    locale: `${SupportedLocaleEnum}`
  }): Promise<PreviewTask | InfoScreen | Welcome | undefined> => {
    const dolphinApi = await this.getDolphinApi()
    const response = await this.call(async () => dolphinApi?.getContentPreviewByRef(ref, locale))

    return response?.data
  }

  sendPreviewMessage = async ({
    ref,
    locale,
    messageBody,
  }: {
    ref: string
    locale: `${SupportedLocaleEnum}`
    messageBody: PreviewMessageBody
  }): Promise<void> => {
    const dolphinApi = await this.getDolphinApi()
    await this.call(async () => dolphinApi?.sendPreviewMessageByRef(ref, locale, messageBody))
  }

  sendSupportMessage = async ({
    message,
    email,
  }: {
    message: string
    email?: string
  }): Promise<void> => {
    const sharedApi = await this.getSharedApi()
    await sharedApi?.sendEmailToSupport({ message, email })
  }

  getChatMessages = async ({
    limit,
    offset,
  }: {
    limit: number
    offset?: number
  }): Promise<ChatMessage[] | undefined> => {
    const dolphinApi = await this.getDolphinApi()

    const response = await this.call(async () => dolphinApi?.getUserChatMessages(limit, offset))

    return response?.data
  }

  sendChatMessage = async (message: string): Promise<ChatMessage | undefined> => {
    const dolphinApi = await this.getDolphinApi()

    const response = await this.call(async () => dolphinApi?.addUserChatMessage({ content: message }))
    return response?.data
  }

  markChatMessagesAsRead = async (): Promise<void> => {
    const dolphinApi = await this.getDolphinApi()
    await this.call(async () => dolphinApi?.markUserChatMessagesAsRead())
  }

  private getInfoApi = (): InfoApi => {
    return new InfoApi(
      new Configuration({
        basePath: this.tunaUrl,
        baseOptions: this.getBaseOptions(tunaApiVersion),
      }),
    )
  }

  getInfo = async (): Promise<Info | undefined> => {
    const infoApi = this.getInfoApi()
    const response = await this.call(async () => infoApi.getInfo(), [504])

    return response?.data
  }

  getSiteIntro = async (locale: SupportedLocaleEnum, siteKey: string): Promise<SiteIntro[] | undefined> => {
    const infoApi = this.getInfoApi()
    const response = await this.call(async () => infoApi.getSiteIntro(locale, siteKey))

    return response?.data
  }

  getReleases = async (
    locale: SupportedLocaleEnum,
    offset: number,
    limit: number,
  ): Promise<ReleaseInfo[] | undefined> => {
    const infoApi = this.getInfoApi()
    const response = await this.call(async () => infoApi?.getReleaseInfo(locale, limit, offset, 'dolphin'))

    return response?.data
  }
}

export { ApiClient }
export const api = new ApiClient()
