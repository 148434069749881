import { useToastsStore } from '@stores/toasts'
import { Colors } from '@vetahealth/fishing-gear/colors'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import { ScreenLayout } from '../../Layouts'
import { BottomSheet } from '../BottomSheet'
import { Button } from '../Button'
import { Dialog } from '../Dialog'
import { Box, Content, Label } from './styles'

export function OverlayPlayground(props: any) {
  const [isDialogVisible, setDialogVisibility] = useState(false)
  const [isBottomSheetVisible, setBottomSheetVisibility] = useState(false)
  const [addToast] = useToastsStore((state) => [state.addToast])

  return (
    <ScreenLayout title="Overlays" hasBack>
      <Content>
        <Box>
          <Label>BottomSheet</Label>
          <Button label="Open BottomSheet" onPress={() => setBottomSheetVisibility(true)} />
          <BottomSheet onBackdropPress={() => setBottomSheetVisibility(false)} isVisible={isBottomSheetVisible}>
            <Button label="BottomSheet" />
          </BottomSheet>
        </Box>
        <Box>
          <Label>Modal</Label>
          <Button label="Open Modal" onPress={() => props.navigation.navigate('PlaygroundModal')} />
        </Box>
        <Box>
          <Label>Toast</Label>
          <Button
            label="Toast"
            onPress={() =>
              addToast({
                key: 'test1',
                title: 'Hola',
                message: 'This is a toast component with 12s duration',
              })
            }
          />
        </Box>
        <Box>
          <Button label="Simple Toast" onPress={() => addToast({ message: 'This is a simple toast.' })} />
        </Box>
        <Box>
          <Button
            label="Toast with actions"
            onPress={() =>
              addToast({
                title: 'Action Toast',
                message: 'This is an action toast.',
                infinite: true,
                isCancable: false,
                icon: ['fas', 'info'],
                iconColor: Colors.mauve800,
                primaryAction: {
                  label: 'Accept',
                  onPress: noop,
                },
                secondaryAction: {
                  label: 'Cancel',
                  onPress: noop,
                },
              })
            }
          />
        </Box>
        <Box>
          <Button
            label="Toast"
            onPress={() =>
              addToast({
                icon: ['fas', 'info'],
                title: 'Nice',
                onPress: () =>
                  addToast({
                    title: 'Upsii',
                    message: 'This is a cancable toast.',
                    duration: 6000,
                  }),
              })
            }
          />
        </Box>
        <Box>
          <Button
            label="Custom duration Toast"
            onPress={() =>
              addToast({
                title: 'Upsii',
                message: 'This is a custom duration toast. (6s)',
                duration: 6000,
              })
            }
          />
        </Box>
        <Box>
          <Button
            label="Toast not cancable"
            onPress={() =>
              addToast({
                title: 'Upsii',
                message: 'This is a non cancable toast.',
                isCancable: false,
              })
            }
          />
        </Box>
        <Box>
          <Button
            label="Toast with action"
            onPress={() =>
              addToast({
                title: 'Task',
                message: 'Notification with onPress (console)',
                onPress: () => console.log('pressed'),
              })
            }
          />
        </Box>
        <Box>
          <Label>Dialog</Label>
          <Button label="Open Dialog" onPress={() => setDialogVisibility(true)} />
          <Dialog
            title="Are you sure?"
            message="All your data will be deleted if you click yes."
            isVisible={isDialogVisible}
            primaryAction={{
              label: 'Delete',
              onPress: () => setDialogVisibility(false),
            }}
            secondaryAction={{
              label: 'Cancel',
              onPress: () => setDialogVisibility(false),
            }}
          />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
