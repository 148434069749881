import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { t } from '@lib/i18n'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { GenericValueEventWithId, TrackingChartAssetTrackingTypeEnum } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import { IntervalDates, IntervalType } from './types'

const color = {
  vitals: Colors.ruby500,
  biometrics: Colors.ink400,
  activity: Colors.mint300,
  statistics: Colors.plum400,
}

export const getStaticTrackingConfig: Record<
  TrackingChartAssetTrackingTypeEnum,
  () => { icon: IconProp; color: Colors; title: string }
> = {
  [TrackingChartAssetTrackingTypeEnum.BloodGlucose]: () => ({
    icon: ['fad', 'droplet'],
    color: color.vitals,
    title: t('tracking.bloodGlucose.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.BloodPressure]: () => ({
    icon: ['fad', 'wave-pulse'],
    color: color.vitals,
    title: t('tracking.bloodPressure.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.Height]: () => ({
    icon: ['fad', 'person-arrow-up-from-line'],
    color: color.biometrics,
    title: t('tracking.height.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.Weight]: () => ({
    icon: ['fad', 'weight-scale'],
    color: color.biometrics,
    title: t('tracking.weight.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.HeartRate]: () => ({
    icon: ['fad', 'heart-pulse'],
    color: color.vitals,
    title: t('tracking.heartRate.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.HeartRateVariability]: () => ({
    icon: ['fad', 'heart-pulse'],
    color: color.vitals,
    title: t('tracking.heartRateVariability.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.RespiratoryRate]: () => ({
    icon: ['fad', 'lungs'],
    color: color.vitals,
    title: t('tracking.respiratoryRate.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.BodyTemperature]: () => ({
    icon: ['fad', 'temperature-three-quarters'],
    color: color.vitals,
    title: t('tracking.bodyTemperature.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.QualityOfLife]: () => ({
    icon: ['fad', 'hand-holding-seedling'],
    color: color.statistics,
    title: t('tracking.qualityOfLife.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.Sleep]: () => ({
    icon: ['fad', 'bed'],
    color: color.activity,
    title: t('tracking.sleep.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.Steps]: () => ({
    icon: ['fad', 'shoe-prints'],
    color: color.activity,
    title: t('tracking.steps.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.MedicationAdherence]: () => ({
    icon: ['fad', 'capsules'],
    color: color.statistics,
    title: t('tracking.medicationAdherence.title'),
  }),
  [TrackingChartAssetTrackingTypeEnum.BloodOxygen]: () => ({
    icon: ['fad', 'droplet-percent'],
    color: color.vitals,
    title: t('tracking.bloodOxygen.title'),
  }),
}

export function getIntervalDateLabel({
  intervalType,
  intervalDates,
}: {
  intervalType: IntervalType
  intervalDates: IntervalDates
}): string {
  // apply locale locally to change dayjs instance created with previous locale
  const intervalStart = intervalDates[0].locale(dayjs.locale())
  const intervalEnd = intervalDates[1].locale(dayjs.locale())

  const isCurrent = intervalStart.isSame(dayjs(), intervalType)
  const isCurrentYear = intervalStart.isSame(dayjs(), 'year')

  if (intervalType === 'day') {
    if (isCurrent) return t('date.today')
    return intervalStart.format(t('date.formats.dayOfMonth', { year: isCurrentYear ? '' : 'YYYY' }))
  }

  if (intervalType === 'week') {
    if (isCurrent) return t('date.thisWeek')
    if (!intervalStart.isSame(intervalEnd, 'month')) {
      // different month same previous year
      if (intervalStart.isSame(intervalEnd, 'year') && !isCurrentYear) {
        return `${intervalStart.format(t('date.formats.dayMonth'))} - ${intervalEnd.format(
          t('date.formats.dayMonth'),
        )} ${intervalEnd.format('YYYY')}`
      }

      // different month, different year (dec - jan)
      if (!intervalStart.isSame(intervalEnd, 'year')) {
        return `${intervalStart.format(t('date.formats.dayMonthYear'))} - ${intervalEnd.format(
          t('date.formats.dayMonthYear'),
        )}`
      }

      // different month
      return `${intervalStart.format(t('date.formats.dayMonth'))} - ${intervalEnd.format(t('date.formats.dayMonth'))}`
    }

    // same month
    return t('date.monthYear', {
      start: intervalStart.format(t('date.formats.day')),
      end: intervalEnd.format(t('date.formats.day')),
      month: intervalEnd.format(t('date.formats.month')),
      year: isCurrentYear ? '' : intervalEnd.format('YYYY'),
    })
  }

  // month
  if (isCurrent) return t('date.thisMonth')
  return `${intervalStart.format(
    t('date.monthLong', {
      year: isCurrentYear ? '' : intervalEnd.format('YYYY'),
    }),
  )}`
}

export function showTrackingInsights(events: GenericValueEventWithId[]): boolean {
  return (
    events.some(({ timestamp }) => dayjs(timestamp).isSameOrAfter(dayjs().subtract(30, 'days'))) &&
    events[0].type === 'bloodPressure'
  )
}

export function getTrackingInsights(
  events: GenericValueEventWithId[],
  days: number,
):
  | {
      average: { value: number; additionalValue?: number }
      max: { value: number; additionalValue?: number }
      unit: string
    }
  | undefined {
  const start = dayjs().subtract(days, 'days')
  const eventsInDays = events.filter((event) => dayjs(event.timestamp).isAfter(start))

  if (!eventsInDays.length) return
  if (eventsInDays.length === 1) return { average: eventsInDays[0], max: eventsInDays[0], unit: eventsInDays[0].unit }

  const first = dayjs(eventsInDays[0].timestamp)
  const last = dayjs(eventsInDays[eventsInDays.length - 1].timestamp)

  const average = eventsInDays.reduce(
    (result, current, index) => {
      if (!index) return result

      const previous = eventsInDays[index - 1]
      const weight = dayjs(current.timestamp).diff(dayjs(previous.timestamp)) / last.diff(first)
      const avg = {
        value: (previous.value + current.value) / 2,
        additionalValue: ((previous.additionalValue ?? 0) + (current.additionalValue ?? 0)) / 2,
      }

      return {
        value: result.value + avg.value * weight,
        additionalValue: result.additionalValue + avg.additionalValue * weight,
      }
    },
    { value: 0, additionalValue: 0 },
  )

  const max = eventsInDays.reduce(
    (result, current) => {
      if (current.value > result.value) {
        return { value: current.value, additionalValue: current.additionalValue ?? 0 }
      }

      return result
    },
    { value: 0, additionalValue: 0 },
  )

  return { average, max, unit: events[0].unit }
}
