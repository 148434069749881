import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import noop from 'lodash/noop'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Platform, TextInput } from 'react-native'

import { Button } from '@components/Elements/Button'
import { CodeInput, DateInputSimple } from '@components/Elements/Input'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { Input, getInputSchema } from '@lib/validation'
import { DeepLinkParams, OnboardingStep, isDeepLinkValid, useOnboardingStore } from '@stores/onboarding'
import { useToastsStore } from '@stores/toasts'

import {
  ButtonWrapper,
  ContentWrapper,
  ErrorMessage,
  Label,
  OnboardingAnimationWrapper,
  Title,
  useOnboardingAnimation,
} from './styles'

export function Otp({ params }: { params?: DeepLinkParams }) {
  const t = useI18n()
  const dateInputRef = useRef<TextInput>(null)
  const [error, setError] = useState('')
  const style = useOnboardingAnimation()
  const [addToast] = useToastsStore((state) => [state.addToast])
  const [input, requestOtp, verifyOtp, updateInput, hasDobVerification, replace] = useOnboardingStore((state) => [
    state.input,
    state.requestOtp,
    state.verifyOtp,
    state.updateInput,
    state.hasDobVerification,
    state.replace,
  ])

  const inputFields: Array<keyof Input> = ['otp']

  if (hasDobVerification) inputFields.push('dateOfBirth')

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    getValues,
    reset,
    setValue,
  } = useForm<{ otp?: string; dateOfBirth?: Date }>({
    resolver: yupResolver(getInputSchema().pick(hasDobVerification ? ['otp', 'dateOfBirth'] : ['otp'])),
    defaultValues: {
      otp: params?.otp || input.otp,
      ...(hasDobVerification && {
        dateOfBirth: input.dateOfBirth ? dayjs(input.dateOfBirth).toDate() : undefined,
      }),
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleFormSubmit = useDebounce(() => {
    if (params?.otp && !isDeepLinkValid(params)) {
      replace([OnboardingStep.INTRO, OnboardingStep.EXPIRED])
      return
    }

    handleSubmit(
      ({ otp, dateOfBirth }) => {
        updateInput({
          otp,
          ...(hasDobVerification && { dateOfBirth }),
        })
        verifyOtp()
          .then(() => trackEvent(TrackingEvent.onboardingSignedIn))
          .catch((error: Error) => {
            if (error?.cause === 'InvalidOtpError') {
              if (params?.otp) {
                // otp not expired but already used
                replace([OnboardingStep.INTRO, OnboardingStep.EXPIRED])
              } else {
                setError(t('error.invalidOtp', { otp }))
                reset({ otp: '', dateOfBirth: getValues()?.dateOfBirth })
              }
            } else if (error?.cause === 'IncorrectDobError') {
              setError(t('error.invalidDob'))
            } else if (error?.cause === 'AccountIsLockedError') {
              setError(t('error.accountLocked'))
            }
          })
      },
      () => {
        setError('')
      },
    )()
  }, [params])

  const handleResendCode = useDebounce(() => {
    trackEvent(TrackingEvent.onboardingResendCodeConfirmPressed)
    requestOtp().then(() => {
      addToast({
        message: t('onboarding.newCodeSent'),
      })
    })
  })

  function handleOtpSubmit(): void {
    if (!hasDobVerification) {
      handleFormSubmit()
    } else if (!getValues().dateOfBirth) {
      dateInputRef.current?.focus()
    }
  }

  useEffect(() => {
    if (params?.userId) dateInputRef.current?.focus()
  }, [params?.userId])

  useEffect(() => {
    if (params?.otp) setValue('otp', params.otp)
  }, [params?.otp])

  return (
    <OnboardingAnimationWrapper style={style}>
      <ContentWrapper>
        {!!error && <ErrorMessage message={error} />}
        <Title>{t('onboarding.verify')}</Title>
        {!params?.otp && (
          <>
            <Label>
              {input.phone
                ? t('onboarding.sentCodeToNumber', {
                    phone: parsePhoneNumberWithError(input.phone).format('NATIONAL'),
                  })
                : t('onboarding.sentCodeToYou')}
            </Label>
            <Controller
              control={control}
              name="otp"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <CodeInput
                  length={6}
                  value={value}
                  onChange={onChange}
                  onSubmitEditing={handleOtpSubmit}
                  error={error?.message}
                  enterKeyHint={Platform.select({
                    ios: 'done',
                    android: 'next',
                  })}
                  inputMode="numeric"
                  autoFocus
                  hintAction={{
                    icon: ['far', 'rotate-right'],
                    title: t('onboarding.dialog.otp.title'),
                    message: t('onboarding.dialog.otp.message'),
                    onOpen: () => trackEvent(TrackingEvent.onboardingResendCodePressed),
                    primaryAction: {
                      label: t('onboarding.dialog.otp.resend'),
                      onPress: handleResendCode,
                    },
                    secondaryAction: {
                      label: t('actions.cancel'),
                      onPress: noop,
                    },
                  }}
                />
              )}
            />
          </>
        )}
        {hasDobVerification && (
          <>
            <Label>{params?.otp ? t('onboarding.short.enterDateOfBirth') : t('onboarding.enterDateOfBirth')}</Label>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateInputSimple
                  ref={dateInputRef}
                  date={value && dayjs(value)}
                  onChange={(date) => onChange(date?.toDate())}
                  onSubmitEditing={handleFormSubmit}
                  error={error?.message}
                />
              )}
            />
          </>
        )}
        <ButtonWrapper>
          <Button fullWidth isLoading={isSubmitting} onPress={handleFormSubmit} label={t('actions.done')} />
        </ButtonWrapper>
      </ContentWrapper>
    </OnboardingAnimationWrapper>
  )
}
