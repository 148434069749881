import { IntervalType } from '@components/Tracking'
import { t } from '@lib/i18n'
import { GenericValueEventWithId } from '@vetahealth/tuna-can-api'
import dayjs, { Dayjs } from 'dayjs'
import { isNumber } from 'lodash'
import groupBy from 'lodash/groupBy'
import meanBy from 'lodash/meanBy'
import range from 'lodash/range'
import { ChartItems, GetIntervalChartDataParams, IntervalChartData, Ticks } from './types'

function getTicks(intervalType: IntervalType, intervalStart: Dayjs, intervalEnd: Dayjs): Ticks {
  const intervalDays = intervalEnd.add(1, 'day').diff(intervalStart, 'days')

  let ticks: Dayjs[] = []
  let formatter: (value: number) => string = (value) => dayjs(value).format('lll')

  if (intervalType === 'day') {
    formatter = (value) => dayjs(value).format(t('date.hourShort'))
    ticks = range(6)
      .map((_, index) => intervalStart.add(index * 4, 'hours'))
      .concat(intervalEnd)
  } else if (intervalType === 'week') {
    formatter = (value) => dayjs(value).format('ddd')
    ticks = range(7).map((_, index) => intervalStart.add(12, 'hours').add(index, 'days'))
  } else if (intervalType === 'month') {
    formatter = (value) => dayjs(value).format('D')
    ticks = range(6)
      .map((_, index) => intervalStart.add(index * 5, 'days').subtract(index ? 1 : 0, 'days'))
      .concat(intervalEnd)
  } else if (intervalType === 'year') {
    formatter = (value) => dayjs(value).format('MMM').substring(0, 1)
    ticks = range(12).map((_, index) => intervalStart.add(index, 'month'))
  }

  return {
    ticks: ticks.map((tick) => tick.valueOf()),
    formatter,
    tickCount: intervalDays || 24,
  }
}

export function getAveragedData({
  data,
  dataKeys,
}: Pick<GetIntervalChartDataParams, 'data' | 'dataKeys'>): GenericValueEventWithId[] | undefined {
  const groupedData = groupBy(data, ({ timestamp }) => dayjs(timestamp).startOf('day').valueOf())

  if (Object.values(groupedData).some((group) => group.length > 1)) {
    return Object.entries(groupedData).map(([timestamp, group]) => ({
      ...group[0],
      timestamp: dayjs(Number(timestamp)).toISOString(),
      ...dataKeys.reduce((keysObject, { key }) => Object.assign(keysObject, { [key]: meanBy(group, key) }), {}),
    }))
  }
}

// native
export function getIntervalData({ data, dataKeys, intervalDates, intervalType }: GetIntervalChartDataParams) {
  const intervalStart = intervalDates[0].startOf('day')
  const intervalEnd = intervalDates[1].endOf('day')

  const ys: { interval: number[]; all: number[] } = { interval: [], all: [] }

  const intervalData = data
    .filter((event) => {
      const isInInterval =
        dayjs(event.timestamp).isSameOrAfter(intervalStart) && dayjs(event.timestamp).isSameOrBefore(intervalEnd)

      dataKeys.forEach(({ key }) => {
        if (isNumber(event[key])) {
          ys.all.push(event[key])

          if (isInInterval) ys.interval.push(event[key])
        }
      })

      return isInInterval
    })
    .map((event) => ({ ...event, timestamp: dayjs(event.timestamp).valueOf() }))

  const y: { interval: [number, number]; all: [number, number] } = {
    interval: [Math.min(...ys.interval), Math.max(...ys.interval)],
    all: [Math.min(...ys.all), Math.max(...ys.all)],
  }

  const { ticks, formatter, tickCount } = getTicks(intervalType, intervalStart, intervalEnd)

  let domain: { y: [number, number] } | undefined

  if (intervalData.length < 1) {
    domain = y.all[0] === y.all[1] ? { y: [Number(y.all[0]) * 0.9, Number(y.all[1]) * 1.1] } : { y: y.all }
  } else {
    domain =
      y.interval[0] === y.interval[1]
        ? {
            y: [Number(y.interval[0]) * 0.9, Number(y.interval[1]) * 1.1],
          }
        : { y: y.interval }
  }

  return { intervalData, ticks, formatter, domain, tickCount }
}

// web
export function getIntervalChartData({
  data,
  dataKeys,
  intervalType,
  intervalDates,
  getCustomChartData,
}: GetIntervalChartDataParams): IntervalChartData {
  const intervalStart = intervalDates[0].startOf('day')
  const intervalEnd = intervalDates[1].endOf('day')

  const intervalChartData: ChartItems = {}
  const ys: { interval: number[]; all: number[] } = { interval: [], all: [] }

  data.forEach((event) => {
    const isInInterval =
      dayjs(event.timestamp).isSameOrAfter(intervalStart) && dayjs(event.timestamp).isSameOrBefore(intervalEnd)

    dataKeys.forEach(({ key, label }) => {
      const timestamp = dayjs(event.timestamp)

      let y = Number(event[key])
      let x = timestamp.valueOf()
      let y0: number | undefined

      if (getCustomChartData) {
        ;({ x, y, y0 } = getCustomChartData(event, key))
      }

      ys.all.push(y)

      if (isInInterval) {
        intervalChartData[label] = [...(intervalChartData[label] || []), { x, y, y0, unit: event.unit }]
        ys.interval.push(y)
      }
    })
  })

  const y: { interval: [number, number]; all: [number, number] } = {
    interval: [Math.min(...ys.interval), Math.max(...ys.interval)],
    all: [Math.min(...ys.all), Math.max(...ys.all)],
  }

  let domain: IntervalChartData['domain']

  if (Object.values(intervalChartData).every((data) => data.length < 1)) {
    domain = y.all[0] === y.all[1] ? { y: [Number(y.all[0]) * 0.9, Number(y.all[1]) * 1.1] } : { y: y.all }
  } else {
    domain =
      y.interval[0] === y.interval[1]
        ? {
            y: [Number(y.interval[0]) * 0.9, Number(y.interval[1]) * 1.1],
          }
        : { y: y.interval }
  }

  const { ticks, formatter, tickCount } = getTicks(intervalType, intervalStart, intervalEnd)

  return { intervalChartData, ticks, formatter, domain, tickCount }
}
