import { Colors } from '@vetahealth/fishing-gear/colors'
import styled from 'styled-components/native'

import { Icon } from '../Icon'
import { Loading } from '../Loading'
import { Pressable } from '../Pressable'
import { Text } from '../Text'
import { ButtonVariant } from './types'

export const BaseButton = styled(Pressable)<{ variant: `${ButtonVariant}` }>`
  background-color: ${({ variant, theme, isDisabled }) =>
    isDisabled
      ? theme.colors.secondaryBackground
      : {
          [ButtonVariant.primary]: theme.colors.primary,
          [ButtonVariant.secondary]: theme.colors.secondary,
          [ButtonVariant.plain]: 'transparent',
          [ButtonVariant.danger]: Colors.ruby600,
        }[variant]};
`

export const ButtonLabel = styled(Text).attrs({
  weight: 'semiBold',
})<{ variant: `${ButtonVariant}`; isDisabled?: boolean }>`
  color: ${({ theme, color, variant, isDisabled }) =>
    isDisabled
      ? theme.colors.disabled
      : color ||
        {
          [ButtonVariant.primary]: Colors.white,
          [ButtonVariant.secondary]: theme.colors.primary,
          [ButtonVariant.plain]: theme.colors.text,
          [ButtonVariant.danger]: Colors.white,
        }[variant]};
`

export const ButtonIcon = styled(Icon).attrs(({ theme, isDisabled, color, variant = ButtonVariant.primary }) => ({
  color: isDisabled
    ? theme.colors.disabled
    : color ||
      {
        [ButtonVariant.primary]: Colors.white,
        [ButtonVariant.secondary]: theme.colors.primary,
        [ButtonVariant.plain]: theme.colors.text,
        [ButtonVariant.danger]: Colors.white,
      }[variant],
}))``

export const ButtonLoading = styled(Loading).attrs(({ theme, isDisabled, color, variant = ButtonVariant.primary }) => ({
  color: isDisabled
    ? theme.colors.disabled
    : color ||
      {
        [ButtonVariant.primary]: Colors.white,
        [ButtonVariant.secondary]: theme.colors.secondaryText,
        [ButtonVariant.plain]: theme.colors.text,
        [ButtonVariant.danger]: Colors.white,
      }[variant],
}))``
