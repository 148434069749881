import { takeRight } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import {
  DomainTuple,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory'
import { Legend } from '../Legend'
import { getIntervalChartData } from '../helpers'
import { chartPadding } from '../styles'
import { getTheme } from '../theme'
import { ChartItem, ChartProps } from '../types'

let states: Array<'activated' | 'deactivated'> = []

export function BarChart({
  data,
  dataKeys,
  unit,
  intervalType,
  intervalDates,
  getDisplayValue,
  getDisplayTime,
  horizontalPadding = 0,
  options,
  onActive,
}: ChartProps) {
  const theme = useTheme()
  const [activeData, setActiveData] = useState<ChartItem[]>()

  const { intervalChartData, ticks, formatter, tickCount, domain } = useMemo(() => {
    return getIntervalChartData({
      data,
      dataKeys,
      intervalType,
      intervalDates,
      getCustomChartData: options?.getCustomChartData,
    })
  }, [data, dataKeys, intervalType, intervalDates])

  function handleActivated(items: ChartItem[]): void {
    states.push('activated')
    setActiveData(items)
  }

  function handleDeactivated(): void {
    states.push('deactivated')
    if (takeRight(states, 2).every((state) => state === 'deactivated')) {
      states = []
      setActiveData(undefined)
    }
  }

  useEffect(() => {
    onActive?.(!!activeData)
  }, [activeData])

  if (!Object.keys(intervalChartData).length) return null

  const yAxis = options?.yAxis?.(domain.y)

  return (
    <View>
      <Legend
        activeData={activeData}
        getDisplayValue={getDisplayValue}
        getDisplayTime={getDisplayTime}
        intervalType={intervalType}
        dataKeys={dataKeys}
        unit={unit}
      />
      <VictoryChart
        padding={{ ...chartPadding, left: 40 }}
        domainPadding={{ x: [40, 20], y: 10 }}
        width={theme.width - horizontalPadding}
        height={theme.height / 3}
        theme={getTheme(theme)}
        containerComponent={
          <VictoryVoronoiContainer
            style={{ height: theme.height / 3 }}
            voronoiDimension={options?.voronoiDimension === 'all' ? undefined : 'x'}
            onActivated={handleActivated}
            onDeactivated={handleDeactivated}
          />
        }
      >
        <VictoryAxis
          tickValues={ticks}
          tickFormat={formatter}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
        />

        <VictoryAxis
          dependentAxis
          domain={(yAxis?.domain || domain) as { y: DomainTuple }}
          tickValues={yAxis?.ticks}
          tickFormat={options?.yFormatter}
          tickLabelComponent={<VictoryLabel textAnchor="middle" backgroundStyle={{ fill: theme.colors.background }} />}
        />

        {dataKeys.map(({ label, color }) => {
          return (intervalChartData[label] || []).map((item, index) => {
            return (
              <VictoryGroup key={index} color={color}>
                <VictoryBar
                  name={label + index}
                  data={[item]}
                  barWidth={100 / tickCount}
                  cornerRadius={{
                    top: Math.ceil((100 / tickCount) * 0.1),
                    bottom: Math.ceil((100 / tickCount) * 0.1),
                  }}
                  style={{
                    data: {
                      fill: ({ active }: { active?: boolean }) => (active ? theme.colors.primary : color),
                    },
                  }}
                  animate={{ duration: 300, onLoad: { duration: 150 } }}
                />
              </VictoryGroup>
            )
          })
        })}
      </VictoryChart>
    </View>
  )
}
