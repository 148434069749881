import React from 'react'
import styled from 'styled-components/native'
import { ErrorText } from '../Text'

const ErrorWrapper = styled.View<{ marginLeft?: number }>`
  width: 100%;
  flex-direction: row;
  margin-left: ${({ marginLeft = 23 }) => marginLeft}px;
  margin-top: 5px;
`

export function InputError({ error, marginLeft }: { error?: string; marginLeft?: number }): JSX.Element | null {
  if (!error) return null

  return (
    <ErrorWrapper marginLeft={marginLeft}>
      <ErrorText>{error}</ErrorText>
    </ErrorWrapper>
  )
}
