import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isWeb } from '@lib/constants'
import exists from '@lib/exists'
import { t } from '@lib/i18n'
import { useAuthStore } from '@stores/auth'
import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  ValidUnits,
  convert,
  defaultUnits,
  getDisplayValue,
  sleepShiftHour,
} from '@vetahealth/fishing-gear/conversions'
import { CardTypeEnum, GenericValueEventWithId, TrackingTypeEnum } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import { BarChart, LineChart, SleepChart } from '../Elements/Charts'
import { getStaticTrackingConfig } from './helpers'
import { AddValue, ChartData, IntervalType, TrackingChartAssetTrackingTypeWithoutHeightEnum } from './types'

function isDoubleUnit(unit: string): boolean {
  return unit.includes('_')
}

export interface TrackingConfig<TrackingType extends TrackingChartAssetTrackingTypeWithoutHeightEnum> {
  icon: IconProp
  color: Colors
  title: string
  unit: ValidUnits[TrackingType]
  addValue?: AddValue
  intervals?: IntervalType[]
  normalize?: (trackingEvents: GenericValueEventWithId[]) => GenericValueEventWithId[]
  getDisplayValue: (value: number | number[], unit: string) => string
  getDisplayTime?: (params: {
    timestamp: string | number
    value?: number
    offset?: number
    intervalType: IntervalType
  }) => string
  getChart: (trackingEvents: GenericValueEventWithId[], intervalType: IntervalType) => ChartData
}

export function getTrackingConfig<TrackingType extends TrackingChartAssetTrackingTypeWithoutHeightEnum>(
  trackingType: `${TrackingType}`,
): TrackingConfig<TrackingType> {
  const user = useAuthStore.getState().user
  const isTrackedByDevice = !!user?.deviceTrackingTypes.includes(trackingType as TrackingTypeEnum)

  function createGetDisplayValue(
    targetUnit?: ValidUnits[keyof ValidUnits],
  ): TrackingConfig<TrackingType>['getDisplayValue'] {
    return (value, unit) => {
      return getDisplayValue(value, unit, trackingType, targetUnit || unit)
    }
  }

  const getBloodPressureTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.BloodPressure> => {
    const unit = (user?.preferences.units.bloodPressure as ValidUnits['bloodPressure']) || defaultUnits.bloodPressure
    const { color, icon, title } = getStaticTrackingConfig.bloodPressure()

    const systolic = t('tracking.bloodPressure.labels.systolic')
    const diastolic = t('tracking.bloodPressure.labels.diastolic')

    return {
      icon,
      color,
      title,
      unit,
      addValue: [
        {
          key: 'value',
          label: systolic,
          placeholder: `${systolic} ${unit}`,
        },
        {
          key: 'additionalValue',
          label: diastolic,
          placeholder: `${diastolic} ${unit}`,
        },
      ],
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data: data.map((event) => {
          const [systolic, diastolic] = convert(
            [event.value, event.additionalValue].filter(exists),
            event.unit,
            TrackingTypeEnum.BloodPressure,
            unit,
          )

          return { ...event, value: systolic, additionalValue: diastolic, unit }
        }),
        dataKeys: [
          {
            label: systolic,
            key: 'value',
            color,
          },
          {
            label: diastolic,
            key: 'additionalValue',
            color: Colors.apricot300,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getWeightTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.Weight> => {
    const unit = (user?.preferences.units.weight as ValidUnits['weight']) || defaultUnits.weight
    const { color, icon, title } = getStaticTrackingConfig.weight()

    const weight = t('tracking.weight.labels.weight')

    return {
      icon,
      color,
      title,
      unit,
      addValue: [
        {
          key: 'value',
          label: weight,
          placeholder: `${weight} ${unit.split('_')[0]}`,
        },
        ...(isDoubleUnit(unit)
          ? ([
              {
                key: 'additionalValue',
                label: weight,
                placeholder: `${weight} ${unit.split('_')[1]}`,
              },
            ] as AddValue)
          : []),
      ],
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data: data.map((event) => ({
          ...event,
          unit,
          value: convert(event.value, event.unit, TrackingTypeEnum.Weight, unit.split('_')[0])[0],
        })),
        dataKeys: [
          {
            label: weight,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getHeartRateTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.HeartRate> => {
    const unit = defaultUnits.heartRate
    const { color, icon, title } = getStaticTrackingConfig.heartRate()

    const heartRate = t('tracking.heartRate.labels.heartRate')

    return {
      icon,
      color,
      title,
      unit,
      addValue: [
        {
          key: 'value',
          label: heartRate,
          placeholder: `${heartRate} ${unit}`,
        },
      ],
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data: data.map((event) => ({
          ...event,
          unit,
          value: convert(event.value, event.unit, TrackingTypeEnum.HeartRate, unit)[0],
        })),
        dataKeys: [
          {
            label: heartRate,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getHeartRateVariabilityTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.HeartRateVariability> => {
    const unit = defaultUnits.heartRateVariability
    const { color, icon, title } = getStaticTrackingConfig.heartRateVariability()

    const heartRateVariability = t('tracking.heartRateVariability.labels.heartRateVariability')

    return {
      icon,
      color,
      title,
      unit,
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data,
        dataKeys: [
          {
            label: heartRateVariability,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getBloodGlucoseTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.BloodGlucose> => {
    const unit = (user?.preferences.units.bloodGlucose as ValidUnits['bloodGlucose']) || defaultUnits.bloodGlucose
    const { color, icon, title } = getStaticTrackingConfig.bloodGlucose()

    const bloodGlucose = t('tracking.bloodGlucose.labels.bloodGlucose')

    return {
      icon,
      color,
      title,
      unit,
      addValue: [
        {
          key: 'value',
          label: bloodGlucose,
          placeholder: `${bloodGlucose} ${unit}`,
        },
      ],
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data: data.map((event) => ({
          ...event,
          unit,
          value: convert(event.value, event.unit, TrackingTypeEnum.BloodGlucose, unit)[0],
        })),
        dataKeys: [
          {
            label: bloodGlucose,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getQualityOfLifeTrackingConfig = (): TrackingConfig<typeof CardTypeEnum.QualityOfLife> => {
    const unit = defaultUnits.qualityOfLife
    const { color, icon, title } = getStaticTrackingConfig.qualityOfLife()

    return {
      icon,
      color,
      title,
      unit,
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data,
        dataKeys: [
          {
            label: t('tracking.qualityOfLife.labels.qualityOfLife'),
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getMedicationAdherenceTrackingConfig = (): TrackingConfig<typeof CardTypeEnum.MedicationAdherence> => {
    const unit = defaultUnits.medicationAdherence
    const { color, icon, title } = getStaticTrackingConfig.medicationAdherence()

    return {
      icon,
      color,
      title,
      unit,
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data,
        dataKeys: [
          {
            label: t('tracking.medicationAdherence.labels.medicationAdherence'),
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getSleepTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.Sleep> => {
    const unit = 'min'
    const displayUnit = 'h_min'
    const { color, icon, title } = getStaticTrackingConfig.sleep()

    return {
      icon,
      color,
      title,
      unit,
      intervals: ['week', 'month'],
      normalize: (events) => {
        return events.map((event) => ({
          ...event,
          timestamp: dayjs(event.timestamp).add(sleepShiftHour, 'hours').toISOString(),
        }))
      },
      getDisplayValue: createGetDisplayValue(displayUnit),
      getDisplayTime: ({ timestamp, value = 0, offset = 0 }) => {
        const isCurrentYear = dayjs(timestamp).isSame(dayjs(), 'year')
        const start = dayjs(timestamp).subtract(sleepShiftHour, 'hours').add(offset, 'minutes')
        const end = start.add(value - offset, 'minutes')

        return `${end.format(t('date.formats.dayOfMonth', { year: isCurrentYear ? '' : 'YYYY' }))} ${start.format(
          'LT',
        )} – ${end.format('LT')}`
      },
      getChart: (data) => {
        return {
          data: data.map((event) => {
            return {
              ...event,
              unit,
              value: convert(event.value, event.unit, TrackingTypeEnum.Sleep, unit)[0],
            }
          }),
          dataKeys: [
            {
              label: t('tracking.sleep.labels.sleep'),
              key: 'value',
              color,
            },
          ],
          component: isWeb ? BarChart : SleepChart,
          unit,
          options: {
            voronoiDimension: 'all',
            getCustomChartData: (event, key) => {
              const x = dayjs(event.timestamp).startOf('day').valueOf()
              const y0 = dayjs(event.timestamp).diff(dayjs(x), 'minutes')
              const y = (event[key] ?? 0) + y0

              return { x, y, y0 }
            },
            yAxis: ([min, max]) => {
              if (typeof min === 'number' && typeof max === 'number') {
                const minDomain = 0
                const maxDomain = max + (60 - (max % 60))

                const middle = minDomain + (maxDomain - minDomain) / 2
                const middleTick = middle + (60 - (middle % 60))
                const maxTick = middleTick * 2

                return {
                  domain: { y: [minDomain, maxTick] },
                  ticks: [minDomain, middleTick, maxTick],
                }
              }
            },
            yFormatter: (value) =>
              dayjs().startOf('day').add(value, 'minute').subtract(sleepShiftHour, 'hours').format('h:mm a'),
          },
        }
      },
    }
  }

  const getStepsTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.Steps> => {
    const unit = defaultUnits.steps
    const { color, icon, title } = getStaticTrackingConfig.steps()

    return {
      icon,
      color,
      title,
      unit,
      intervals: ['week', 'month'],
      getDisplayValue: createGetDisplayValue(unit),
      getDisplayTime: ({ timestamp }) => {
        const isCurrentYear = dayjs(timestamp).isSame(dayjs(), 'year')

        return dayjs(timestamp).format(t('date.formats.dayOfMonth', { year: isCurrentYear ? '' : 'YYYY' }))
      },
      getChart: (data) => ({
        data: data.map((event) => ({ ...event, timestamp: dayjs(event.timestamp).add(11, 'hours').toISOString() })),
        dataKeys: [
          {
            label: t('tracking.steps.labels.steps'),
            key: 'value',
            color,
          },
        ],
        component: BarChart,
        unit,
        options: {
          yFormatter: (value) => {
            // keep en fixed: 1000 => 1k
            return Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits: 1,
            })
              .format(value)
              .toLowerCase()
          },
        },
      }),
    }
  }

  const getRespiratoryRateTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.RespiratoryRate> => {
    const unit = defaultUnits.respiratoryRate
    const { color, icon, title } = getStaticTrackingConfig.respiratoryRate()

    const respiratoryRate = t('tracking.respiratoryRate.labels.respiratoryRate')

    return {
      icon,
      color,
      title,
      unit,
      addValue: [
        {
          key: 'value',
          label: respiratoryRate,
          placeholder: `${respiratoryRate} ${unit}`,
        },
      ],
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data,
        dataKeys: [
          {
            label: respiratoryRate,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getBodyTemperatureTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.BodyTemperature> => {
    const unit =
      (user?.preferences.units.bodyTemperature as ValidUnits['bodyTemperature']) || defaultUnits.bodyTemperature
    const { color, icon, title } = getStaticTrackingConfig.bodyTemperature()

    const bodyTemperature = t('tracking.bodyTemperature.labels.bodyTemperature')

    return {
      icon,
      color,
      title,
      unit,
      addValue: [
        {
          key: 'value',
          label: bodyTemperature,
          placeholder: `${bodyTemperature} ${unit}`,
        },
      ],
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data: data.map((event) => ({
          ...event,
          unit,
          value: convert(event.value, event.unit, TrackingTypeEnum.BodyTemperature, unit)[0],
        })),
        dataKeys: [
          {
            label: bodyTemperature,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const getBloodOxygenTrackingConfig = (): TrackingConfig<typeof TrackingTypeEnum.BloodOxygen> => {
    const unit = defaultUnits.bloodOxygen
    const { color, icon, title } = getStaticTrackingConfig.bloodOxygen()

    const bloodOxygen = t('tracking.bloodOxygen.labels.bloodOxygen')

    return {
      icon,
      color,
      title,
      unit,
      getDisplayValue: createGetDisplayValue(unit),
      getChart: (data) => ({
        data: data.map((event) => ({
          ...event,
          unit,
          value: convert(event.value, event.unit, TrackingTypeEnum.BloodOxygen, unit)[0],
        })),
        dataKeys: [
          {
            label: bloodOxygen,
            key: 'value',
            color,
          },
        ],
        component: LineChart,
        unit,
      }),
    }
  }

  const config = {
    bloodPressure: getBloodPressureTrackingConfig(),
    weight: getWeightTrackingConfig(),
    heartRate: getHeartRateTrackingConfig(),
    heartRateVariability: getHeartRateVariabilityTrackingConfig(),
    bloodGlucose: getBloodGlucoseTrackingConfig(),
    qualityOfLife: getQualityOfLifeTrackingConfig(),
    medicationAdherence: getMedicationAdherenceTrackingConfig(),
    sleep: getSleepTrackingConfig(),
    steps: getStepsTrackingConfig(),
    respiratoryRate: getRespiratoryRateTrackingConfig(),
    bodyTemperature: getBodyTemperatureTrackingConfig(),
    bloodOxygen: getBloodOxygenTrackingConfig(),
  }[trackingType] as TrackingConfig<TrackingType>

  return (
    config && {
      ...config,
      ...(isTrackedByDevice && { addValue: undefined }),
      ...(!config.getDisplayTime && {
        getDisplayTime: ({ timestamp }) => {
          const isCurrentYear = dayjs(timestamp).isSame(dayjs(), 'year')
          return `${dayjs(timestamp).format(
            t('date.formats.dayOfMonth', { year: isCurrentYear ? '' : 'YYYY' }),
          )} ${dayjs(timestamp).format('LT')}`
        },
      }),
    }
  )
}
