import { useI18n } from '@lib/i18n'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import styled from 'styled-components/native'
import { Text } from '../Text'
import { LegendProps } from './types'

const Wrapper = styled.View`
  flex-direction: row;
  padding: 10px 0;
  justify-content: center;
`
const ItemWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
`
const ColorItem = styled.View<{ color: Colors }>`
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`

export function Legend({ dataKeys, unit, activeData, getDisplayValue, getDisplayTime, isAveraged }: LegendProps) {
  const t = useI18n()

  return (
    <Wrapper>
      {activeData?.length ? (
        <>
          <Text size="small" weight="semiBold">
            {getDisplayValue(
              activeData.map(({ y, y0 = 0 }) => y - y0),
              activeData[0].unit,
            )}
            <Text size="small" weight="medium">{` - ${getDisplayTime({
              timestamp: activeData[0].x,
              value: activeData[0].y,
              offset: activeData[0].y0,
            })}`}</Text>
          </Text>
        </>
      ) : (
        <>
          {dataKeys.map(({ color, label }) => (
            <ItemWrapper key={label}>
              <ColorItem color={color} />
              <Text size="small" weight="semiBold">
                {label}
              </Text>
            </ItemWrapper>
          ))}
          {unit && (
            <ItemWrapper>
              <Text size="tiny" weight="medium">
                {t('chart.unit', { unit: unit.replace('_', ' ') })}
              </Text>
              {isAveraged && (
                <Text margins={{ left: 10 }} size="tiny" weight="medium">
                  {t('chart.averaged')}
                </Text>
              )}
            </ItemWrapper>
          )}
        </>
      )}
    </Wrapper>
  )
}
