export enum RouteName {
  App = 'app',

  Onboarding = 'onboarding',
  Tabs = 'tabs',
  PseudoTabAddTracking = 'pseudo-tab-add-tracking',

  Messaging = 'messaging',
  Messages = 'messages',
  NewMessageModal = 'new-message',

  Task = 'task',
  ActiveTask = 'active-task',
  TaskInfoModal = 'task-info',

  Preview = 'preview',
  PreviewSearch = 'preview-search',
  PreviewTask = 'preview-task',

  Tracking = 'tracking',
  TrackingList = 'tracking-list',
  TrackingByType = 'tracking-by-type',
  TrackingDetails = 'tracking-details',
  TrackingInsights = 'tracking-insights',
  TrackingEventModal = 'tracking-event',

  Account = 'account',
  AccountList = 'account-list',
  Program = 'program',
  ContactUs = 'contact-us',

  Settings = 'settings',
  SettingsList = 'settings-list',
  Notifications = 'notifications',
  Appearance = 'appearance',
  Language = 'language',
  Units = 'units',

  About = 'about',
  AboutList = 'AboutList',
  Releases = 'releases',
  Licenses = 'licenses',

  Playground = 'playground',

  // global modals
  WelcomeModal = 'welcome',
  ReleaseModal = 'release',
  UpdateAccountModal = 'update-account',
  AddTrackingModal = 'add-tracking',
}
