import { Colors } from '@vetahealth/fishing-gear/colors'
import takeRight from 'lodash/takeRight'
import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'
import { Legend } from '../Legend'
import { getAveragedData, getIntervalChartData } from '../helpers'
import { chartPadding } from '../styles'
import { getTheme } from '../theme'
import { ChartItem, ChartProps } from '../types'

let states: Array<'activated' | 'deactivated'> = []

export function LineChart({
  data,
  dataKeys,
  unit,
  intervalType,
  intervalDates,
  getDisplayValue,
  getDisplayTime,
  horizontalPadding = 0,
  onActive,
  options,
}: ChartProps): JSX.Element | null {
  const theme = useTheme()
  const [activeData, setActiveData] = useState<ChartItem[]>()

  const averagedData = useMemo(() => getAveragedData({ data, dataKeys }), [data])
  const isAveraged = intervalType === 'month' && !!averagedData?.length

  const { intervalChartData, ticks, formatter, domain } = useMemo(() => {
    return getIntervalChartData({
      data: isAveraged ? averagedData : data,
      dataKeys,
      intervalType,
      intervalDates,
      getCustomChartData: options?.getCustomChartData,
    })
  }, [data, dataKeys, intervalType, intervalDates])

  function handleActivated(items: ChartItem[]): void {
    states.push('activated')
    setActiveData(items)
  }

  function handleDeactivated(): void {
    states.push('deactivated')
    if (takeRight(states, 2).every((state) => state === 'deactivated')) {
      states = []
      setActiveData(undefined)
    }
  }

  useEffect(() => {
    onActive?.(!!activeData)
  }, [activeData])

  if (!Object.keys(intervalChartData).length) return null

  return (
    <View>
      <Legend
        intervalType={intervalType}
        activeData={activeData}
        getDisplayValue={getDisplayValue}
        getDisplayTime={getDisplayTime}
        dataKeys={dataKeys}
        unit={unit}
        isAveraged={isAveraged}
      />
      <VictoryChart
        width={theme.width - horizontalPadding}
        height={theme.height / 3}
        domainPadding={{ x: 20, y: 10 }}
        padding={chartPadding}
        theme={getTheme(theme)}
        containerComponent={
          <VictoryVoronoiContainer
            style={{ height: theme.height / 3 }}
            voronoiBlacklist={[
              'cursor-line',
              ...dataKeys.flatMap(({ label }) => [`scatter-${label}`, `cursor-scatter-${label}`]),
            ]}
            voronoiDimension="x"
            disable={isAveraged}
            onActivated={handleActivated}
            onDeactivated={handleDeactivated}
          />
        }
      >
        <VictoryAxis
          tickValues={ticks}
          tickFormat={formatter}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
        />

        <VictoryAxis
          dependentAxis
          domain={domain as { y: DomainTuple }}
          tickLabelComponent={<VictoryLabel textAnchor="start" backgroundStyle={{ fill: theme.colors.background }} />}
        />

        {dataKeys.map(({ label, color }) => (
          <VictoryGroup color={color} key={label} data={intervalChartData[label] ?? []}>
            <VictoryLine name={`line-${label}`} interpolation="monotoneX" />
            {(!isAveraged || intervalChartData[label]?.length === 1) && (
              <VictoryScatter name={`scatter-${label}`} size={intervalChartData[label]?.length === 1 ? 3.5 : 1.5} />
            )}
          </VictoryGroup>
        ))}

        {!!activeData?.length && (
          <VictoryLine
            name="cursor-line"
            data={[
              { x: activeData[0].x, y: domain.y[0] },
              { x: activeData[0].x, y: domain.y[1] },
            ]}
            style={{
              data: {
                stroke: theme.colors.border,
                strokeDasharray: 4,
              },
            }}
          />
        )}

        {activeData?.map((activePoint, index) => (
          <VictoryScatter
            key={`cursor-scatter-${dataKeys[index]?.label}`}
            name={`cursor-scatter-${dataKeys[index]?.label}`}
            size={4}
            data={[{ x: activePoint.x, y: activePoint.y }]}
            style={{
              data: {
                strokeWidth: 4,
                stroke: `${dataKeys[index].color}AA`,
                fill: Colors.white,
              },
            }}
          />
        ))}
      </VictoryChart>
    </View>
  )
}
