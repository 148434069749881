import { isWeb } from '@lib/constants'
import { openExternalLink } from '@lib/linking'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import React from 'react'
import { PixelRatio } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { InlineLinkProps, TextProps } from './types'

export const Text = styled.Text<TextProps>`
  ${({ theme, weight = 'normal' }) => omit(theme.font.weight[weight], isWeb ? [] : ['fontWeight'])}
  ${({ isCentered }) => isCentered && 'text-align: center;'}
  ${({ isItalic }) => isItalic && 'font-style: italic;'}
  ${({ margins }) =>
    margins &&
    `
    margin-top: ${margins.top ?? 0}px;
    margin-bottom: ${margins.bottom ?? 0}px;
    margin-left: ${margins.left ?? 0}px;
    margin-right: ${margins.right ?? 0}px;
  `}
  ${({ hasUnderline, theme, color }) =>
    hasUnderline &&
    `
      text-decoration: underline;
      text-decoration-color: ${color || theme.colors.primary};
    `}
  ${({ theme, color, size = 'medium' }) => `
    color: ${color || theme.colors.text};
    font-size: ${theme.font.size[size] / PixelRatio.getFontScale()}px;
  `}
  ${({ theme, size = 'medium', disableLineHeight }) =>
    !disableLineHeight &&
    `
    line-height: ${Math.round(
      (theme.font.size[size] / PixelRatio.getFontScale()) *
        (theme.font.size[size] / PixelRatio.getFontScale() > 22 ? 1.25 : 1.618),
    )}px;
  `}
`

export const ErrorText = styled(Text).attrs(({ theme }) => ({
  size: 'tiny',
  weight: 'semiBold',
  color: theme.colors.error,
  numberOfLines: 2,
}))``

export const HintText = styled(Text).attrs<TextProps>(({ size, theme }) => ({
  size: size ?? 'small',
  color: theme.colors.hint,
}))``

export function InlineLink({
  children,
  href,
  onPress = noop,
  color,
  size,
  hasUnderline = true,
  weight = 'medium',
  isCentered = false,
  margins,
}: InlineLinkProps) {
  const theme = useTheme()

  function handlePress(): void {
    if (href) {
      openExternalLink(href)
    } else {
      onPress()
    }
  }

  return (
    <Text
      size={size}
      weight={weight}
      onPress={handlePress}
      color={color || theme.colors.primary}
      hasUnderline={hasUnderline}
      isCentered={isCentered}
      margins={margins}
    >
      {children}
    </Text>
  )
}
